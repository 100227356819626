


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import conf from '@/common/conf'
import caseAside from '@/components/case/detail/aside.vue'
import caseForm from '@/components/case/detail/form.vue'
import globalMixins from '@/mixins/global.ts'
import utils from "@/utils/util.ts"

@Component({
  components: {
    caseAside,
    caseForm
  },
  mixins: [globalMixins]
})
export default class ATSViewsCaseDetail extends Vue<globalMixins> {
  @Getter('detail') detail: {
    groups: Array<any>, 
    status: string, 
    creator_id: number,
    id: number,
    has_dcm: boolean,
    latest_reject_log: {
      remark: string
    },
    label_status: string,
    request_review_message: string
  }
  @Action('caseDetail') caseDetail: Function
  @Action('caseDel') caseDel: Function
  @Action('caseReview') caseReview: Function
  @Action('caseReviewToPending') caseReviewToPending: Function
  @Action('caseUpdate') caseUpdate: Function

  loading: boolean = false
  menuIndex: String = '1'
  currentFormData: Object[] = []
  formData: Object = {
    id: null,
    fields: [],
    group_id: null
  }
  currentGroupId: number = 1
  currentFormId: number = 1
  project: any = {}
  account: any = JSON.parse(localStorage.getItem("account"))
  // 普通编辑状态
  editing: boolean = false
  // 自己创建的可以编辑
  isMyEditable: boolean = false
  reviewable: boolean = false
  // 切换时候要记住的位置 新位置 老位置
  currentPosition: any = null
  oldPosition: any = null

  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.isShowEditable()
    this.doDetail()
  }
  get isIdeal() {
    return (conf.judgeDomation() === 'ideal' && this.project.role === 'doctor')
  }
  // 判断标注状态(已标注 & 标注中)显示 查看标注按钮
  get imgLabelViewable () {
    if (!this.detail.has_dcm) {
      return false
    }
    return this.detail.label_status ==='finished' || this.detail.label_status ==='pending'
  }
  // 判断是否展示标注状态 && 判断是否编号可以跳转
  get isLabel () {
    return conf.judgeDomation() === 'ideal' || conf.judgeDomation() === 'myathena'
  }
  // 是否是自己创建的病例
  get isMineCreate () {
    return this.account.id === this.detail.creator_id
  }
  get pendingStatus () {
    return this.detail.status === 'draft'
  }
  get rejectStatus () {
    return this.detail.status === 'pending' || this.detail.status === 'approved'
  }
  get latestRejectLog () {
    return this.detail.latest_reject_log.remark
  }
  get rejectOrPass () {
    return this.project.role === 'professor' || this.project.role === 'leader'
  }
  get requestReviewMessage () {
    return this.detail.request_review_message
  }
  get caseId () {
    return this.detail.id
  }
  goToImgLabel (caseId, hasDcm) {
    if (!hasDcm) {
      this.$confirm('当前病例暂未上传dicom文件, 是否继续跳转?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.routerToLabel(caseId)
      })
    } else {
      this.routerToLabel(caseId)
    }
  }
  routerToLabel (caseId) {
    let { href } = this.$router.resolve({
      name: 'img-label-detail',
      params: {
        id: caseId
      }
    })
    window.open(href, '_blank')
  }
  // url上的参数条件判断
  isShowEditable () {
    // 自己创建的可以编辑
    this.isMyEditable = Number(this.$route.query.mine) === 1 ? true : false
    // 当前病理展示还是编辑
    this.editing = this.$route.name === 'case-add' ? true : false
  }
  rejectReason () {
    this.$notify({
      title: '驳回原因',
      message: this.latestRejectLog,
      duration: 4000,
      type: 'warning'
     })
  }
  labelStatusChange (val) {
    switch (val) {
      case 'finished':
        return '已标注'
      case 'pending':
        return '标注中'
      default:
        return '未标注'
    }
  }
  typeChange (val) {
    switch (val) {
    case 'approved':
      return {name: '已通过', type: 'success'}
    case 'draft':
      return {name: '草稿', type: 'warning'}
    case 'pending':
      return {name: '待审核', type: ''}
    case 'rejected': 
      return {name: '已驳回', type: 'danger'}
    }
  }
  updateDetail (groupId) {
    this.menuIndex = groupId
    this.doDetail()
  }
  menuChange (index, oldIndex) {
    // 先判断是不是会保存，如果不保存就直接去找下一条菜单数据
    if (this.editing) {
      this.reviewable = false
      this.currentPosition = index
      this.oldPosition = oldIndex
      this.onSave()
    } else {
      this.getFormData(index)
    }
    // 最开始版本 需要用户确认是否保存
    // this.editing ? this.$confirm('是否对当前信息进行保存？', '提示', {
    //   confirmButtonText: '保存',
    //   cancelButtonText: '取消',
    //   type: 'warning'
    // })
    // .then(() => {
    //   this.reviewable = false
    //   this.currentPosition = oldIndex
    //   this.oldPosition = oldIndex
    //   this.save()
    // })
    // .catch(() => {
    //   this.getFormData(index)
    // })
    // : this.getFormData(index)
  }
  stopCurrrentPosition () {
    let index = this.oldPosition
    let newIndexArr =  index.split('-')
    // groups id 匹配 - 之前的字符串
    this.currentGroupId = newIndexArr[1]
    let activeIndex = newIndexArr[0] + '-' + this.currentGroupId + '-' + newIndexArr[2]
    this.changeActive(activeIndex)
  }
  async changeActive (index) {
    let caseAside: any = this.$refs.caseAside
    await caseAside.onIndexChange(index)
  }
  // 根据menuindexh取得数据
  getFormData (index) {
    let newIndexArr =  index.split('-')
    // groups id 匹配 - 之前的字符串
    this.currentGroupId = newIndexArr[1]
    // form id  匹配 - 之后的字符串
    this.currentFormId = newIndexArr[2]
    this.menuIndex = newIndexArr[1]
    let groupsData = (this.currentFormData[Number(this.currentGroupId) - 1] as any).forms
    this.formData = Object.assign({}, groupsData[Number(this.currentFormId) - 1])
  }
  doDetail () {
    this.loading = true
    let params = {
      id: this.$route.params.id,
      projectId: this.project.id
    }
    this.caseDetail({ params }).then(() => {
      this.loading = false
      if (this.detail.status === 'draft') {
        this.editing = true
      }
    })
  }
  // 是否编辑
  edit () {
    this.editing = true
  }
  // 提交审核
  turnToPending () {
    let params = {
      id: this.caseId,
      projectId: this.project.id
    }
    this.caseReviewToPending({ params }).then(() => {
      this.successMsg('送审成功!')
      this.routerTo()
    })
  }
  // 保存病例（保存到草稿箱）
  async onSave () {
    let caseForm: any = this.$refs.caseForm
    await caseForm.onSubmit({reviewable: this.reviewable})
  }
  // 保存病例并且提交审核
  async saveAndTurnToPending () {
    this.reviewable = true
    let caseForm: any = this.$refs.caseForm
    await caseForm.onSubmit({reviewable: this.reviewable})
  }
  handleDel () {
    this.$confirm('该病例历将会被删除, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params = {
        id: this.caseId,
        projectId: this.project.id
      }
      this.caseDel({ params }).then(() => {
        this.successMsg('删除成功!')
        this.$router.push('/case/mine')
      })
    })
  }
  // 取消编辑 & 清除表单数据
  cancel () {
    this.editing = false
    let caseForm: any = this.$refs.caseForm
    caseForm.clearValidateForm()
  }
  // 审核通过
  approve () {
    let params = {
        id: this.caseId,
        operation: 'approve',
        projectId: this.project.id
      }
      this.caseReview({ params }).then(() => {
        this.successMsg('审核通过!')
        setTimeout(() => {
          let params = {
            id: this.$route.params.id
          }
          this.$router.push('/case/check?status=pending')
        }, 200)
      })
  }
  // 驳回病例
  rejecte () {
    this.$prompt('请输入驳回原因(必填)', {
     confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /\S/,
      inputErrorMessage: '驳回原因为必填'
    }).then(value => {
      let params = {
        id: this.caseId,
        operation: 'reject',
        remark: value['value'],
        projectId: this.project.id
      }
      this.caseReview({ params }).then(() => {
        this.successMsg('已成功驳回!')
        let params = {
          id: this.$route.params.id
        }
        setTimeout(() => {
          this.$router.push('/case/check?status=rejected')
        }, 200)
      }).catch((err) => {
        this.errorMsg('驳回失败,请稍后重试!')
      })
    })
  }
  // ideal doctor 提交审核跳转到draft
  routerTo () {
    if (this.isIdeal) {
      this.$router.push('/case/mine?status=draft')
    } else {
      this.$router.push('/case/mine?status=pending')
    }
  }
  // 更新、提交case
  caseSave (params) {
    this.caseUpdate({ params }).then(() => {
      this.editing = this.pendingStatus ? true : false
      this.loading = false
      if (this.requestReviewMessage !== '' && this.reviewable) {
        this.warnMsg('当前部分病例保存成功,但未成功提交审核,请填写所有必填字段!')
      } else if (this.reviewable && this.requestReviewMessage === ''){
        // reviewable表示需要提交送审
        this.successMsg('保存并提交送审成功!')
        this.routerTo()
      } else {
        let time = (utils as any).dateFormat('yyyy-MM-dd hh:mm')
        this.successMsg(`${time}  保存成功！`)
        this.getFormData(this.currentPosition)
        
      }
    }).catch((err) => {
      this.loading = false
    })
  }
  @Watch('detail')
  onDetailChange (val: {groups: Array<any>}, oldValue: Object) {
    if (val !== null && val.groups.length > 0) {
      this.currentFormData = val.groups
      let groupsData = (this.currentFormData[Number(this.currentGroupId) - 1] as any).forms
      this.formData = Object.assign({}, groupsData[Number(this.currentFormId) - 1])
    }
  }
}





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({})
export default class ATSComponentsCaseAside extends Vue {
  asideNav: Object[] = []
  activeIndex: string = '1-1-1'
  opens: any[] = ['1', '1-1', '1-1-1', '1--1']

  @Prop()
  aside: {
    groups: any[]
  }
  select (index, indexPath) {
    // 点进了2级菜单, 才切换数据
    if (indexPath.length === 3) {
      this.$emit('menuChange', index, this.activeIndex)
    }
    this.activeIndex = indexPath[2]
  }
  onIndexChange (index) {
    this.$nextTick(() => {
      this.activeIndex = index
    })
  }
  @Watch('aside', { immediate: true, deep: true })
  onAsideChange(val: {groups: Array<Object>}, oldVal: Object) {
    this.asideNav = val.groups
  }
}

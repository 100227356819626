






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class ATSComponentsDicomUpload extends Vue<globalMixins> {
  @Prop()
  fieldId: number
  @Prop()
  fileListValue: any[]

  api: string = ''
  uploadApi: string = ''
  dicomLists: any[] = []
  // 组合成el-uoload能是识别出来的数组，key为(name, url)
  dicomListArr: any[] = []
  // 把后台上传的dicom文件数据, 放在当前组件内部可以操作
  currentDicomLists: any[] = []
  project: object = JSON.parse(localStorage.getItem("projects"))
  created () {
    this.getOperateLists()
    this.dicomListArr = this.resetFileKey(this.fileListValue, this.dicomListArr)
    if (process.env.NODE_ENV === 'production') {
      let domation = window.location.host
      this.api = `https://${domation}/api`
    } else {
      this.api = 'https://test.myathena.cn/api'
    }
    this.uploadApi = this.api + '/files'
  }
  get headers () {
    const Bearer = 'Bearer '
    return {Authorization: (Bearer + localStorage.getItem('token'))}
  }
  // 自己内部可以操作的数组
  getOperateLists () {
    this.currentDicomLists = this.fileListValue
  }
  // 组合成el-uoload能是识别出来的数组(name, url)
  resetFileKey (list, newList) {
    if (list === null) {
      newList = []
    } else {
      list.slice(0, list.length).map((item: any) => {
        newList.push({
          ...item,
          name: item.filename
        })
      })
    }
    return newList
  }
  clearFiles () {
    (this.$refs.uploador as any).clearFiles()
  }
  handleError (err, file, fileList) {
    let msg: String = ''
    msg = err.error ? err.error.message : err
    this.errorMsg(msg)
  }
  beforeUpload (file) {
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      this.errorMsg('上传文件大小不能超过 10MB!')
    }
    return isLt10M
  }
  handleExceed (files, fileList) {
    this.warnMsg('请删除已经存在的文件后再操作!')
  }
  handleRemove (file, fileList: any) {
    this.currentDicomLists = fileList
    this.$emit('handleDicomUpload', fileList, this.fieldId)
  }
  handleSuccess (response, file, fileList) {
    this.$emit('handleDicomUpload', fileList, this.fieldId)
  }
}









































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import imagesUpload from '@/components/common/ImgUpload.vue'
import dicomUpload from '@/components/common/dicomUpload.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    imagesUpload,
    dicomUpload
  },
  mixins: [globalMixins]
})
export default class ATSComponentsCaseForm extends Vue<globalMixins> {
  @Prop()
  caseId: number
  @Prop()
  formData: {
    id: number,
    fields: any[],
    group_id: number
  }
  @Prop()
  editing: boolean
  
  ruleForm: any = {}
  project: any = {}
  imageFieldId: number = 0
  imgLists: any[] = []
  dicomList: any[] = []
  dicomListIds: any[] = []
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
  }
  get rules () {
    let matchRules = {}
    Object.keys(this.formData).length !== 0 && this.formData.fields.length !== 0 && this.formData.fields.map((v: {id: number, is_required: boolean, name: string, type: string}, i) => {
      let obj ={}
      if (v.type === 'number') {
        let key = (v.id).toString() + '.value'
        obj[key] = [{required: v.is_required, message: v.name + '必填!', trigger: 'blur'}]
      } else {
        obj[v.id] = [{required: v.is_required, message: v.name + '必填!', trigger: 'blur'}]
      }
      matchRules = Object.assign({}, matchRules, obj)
    })
    return matchRules
  }
  // 图片上传 / 删除
  handleImageUpload (filelist, id) {
    this.imgLists = []
    let newArr: any = []
    filelist.map((img: any) => {
      img.hasOwnProperty('response') 
      ? newArr.push(img.response.data[0]) 
      : newArr.push(img)
    })
    newArr.map((item) => {
      this.imgLists.push(item.id)
    })
    // // 将当前上传的图片赋到form
    let obj = {}
    obj[id] = this.imgLists
    this.ruleForm[id] = obj[id]
  }
  // dicom上传 / 删除
  handleDicomUpload (filelist, id) {
    this.dicomList = []
    let newArr: any = []
    filelist.map((dicom: any) => {
      dicom.hasOwnProperty('response') 
      ? newArr.push(dicom.response.data[0]) 
      : newArr.push(dicom)
    })
    newArr.map((item) => {
      this.dicomList.push(item.id)
    })
    // // 将当前上传的图片赋到form
    let obj = {}
    obj[id] = this.dicomList
    this.ruleForm[id] = obj[id]
  }
  clearValidateForm () {
    let PassForm = this.$refs.ruleForm as HTMLFormElement
    // 清除验证结果
    if (PassForm) {
      PassForm.clearValidate()
    }
  }
  resetForm (ruleForm) {
    (<HTMLFormElement>this.$refs[ruleForm]).resetFields()
  }
  // 修改详情提交
  onSubmit(queryObj?: any) {
    let PassForm = this.$refs.ruleForm as HTMLFormElement
    PassForm.validate((valid) => {
      if (valid) {
        this.ruleForm = this.getImagesIDs(this.ruleForm)
        let computedFormData = Object.assign({}, this.ruleForm)
        // 将数据组合成后台需要的结构
        let objForm = {}
        for(let item in computedFormData){
          let obj = {}
          let isArray: Boolean = computedFormData[item] instanceof Array
          // 判断为number类型
          if (computedFormData[item] !== null && computedFormData[item] instanceof Object && !isArray) {
            let newItem = computedFormData[item]
            // 不必填的时候 value提交null
            if (newItem.value === '' || newItem.value === null) {
              obj = { 
                id: Number(item),
                value: null
              }
            } else {
              obj = { 
                id: Number(item),
                value: {
                  value: Number(newItem.value),
                  unit: {...newItem.unit}
                }
              }
            }
          } else {
            // 不是number类型
            obj = {
              id: Number(item),
              value: computedFormData[item]
            }
          }
          objForm[item] = obj
        }
        // 删除出现的observe
        let delObj = Object.values(objForm)[0]
        if (Number(Object.values(delObj)[0]) === 0) {
          delete objForm['0']
        }
        // 向后台提交的参数
        let params = {
          projectId: this.project.id,
          caseId: this.caseId,
          query: {
            groups: [{
              id: this.formData.group_id,
              forms: [{
                id: this.formData.id,
                fields: Object.values(objForm)
              }]
            }],
            // 判断传递过来的参数是否具有key: reviewable
            request_review: queryObj.hasOwnProperty('reviewable') ? queryObj.reviewable : false
          }
        }
        this.$emit('caseSave', params)
      } else {
        this.errorMsg('请检查表单必填项是否填写完整！')
        this.$emit('stopCurrrentPosition')
        return false
      }
    })
  }
  // 后台来的图片的转化,只取id
  getImagesIDs (data) {
    let flag = false
    for (let item in data) {
      if (Array.isArray(data[item])) {
        let newItem = []
        data[item].map((v) => {
          if (v.id) {
            flag = true
            newItem.push(v.id)
          } else {
            flag = false
          }
        })
        if (flag) {
          data[item] = newItem
        }
      }
    }
    return data
  }
  // 单位变化 匹配检查条件
  unitChange (unit, allArr, id) {
    let newObj = allArr.find(elem => {
      return elem.value === unit
    })
    this.ruleForm[id].unit.condition = newObj.condition
  }
  // 检查条件变化 匹配单位
  checkChange (condition, allArr, id) {
    let newObj = allArr.find(elem => {
      return elem.condition === condition
    })
    this.ruleForm[id].unit.value = newObj.value
  }
  @Watch('formData', { immediate: true, deep: true })
    onAsideChange(val: {'fields': any[]}, oldVal: Object) {
      if (typeof(val) !== undefined) {
        if (Object.keys(val).length > 0 && val.fields.length > 0) {
          this.ruleForm = {}
          val.fields.map((v, index) => {
            let obj = {}
            let unitArr = []
            let checkArr = []
            // 选择的单位
            let selectedUnit = ''
            // 选择的检查条件
            let selectedCheck = ''
            // type === number要单独转化一下
            if (v.type === 'number') {
              if (v.units && v.units.length > 0) {
                v.units.map((item, index) => {
                  unitArr.push(item.value)
                  checkArr.push(item.condition)
                })
                if (v.value !== null) {
                  if (v.value.unit) {
                    selectedUnit = v.value.unit.value
                    selectedCheck = v.value.unit.condition
                  }
                } else {
                  selectedUnit = unitArr.length > 0 ? unitArr[0] : ''
                  selectedCheck = checkArr.length > 0 ? checkArr[0] : ''
                }
              }
              let unitValue: any = null
              if (v.value) {
                if (v.value.unit) {
                  unitValue = v.value.value
                } else {
                  unitValue = v.value
                }
              }
              obj[v.id] = {
                value: v.value ? unitValue : null,             //数值类型的数
                unit: {                           //数值类型的当前选择单位和检查条件
                  condition: selectedCheck,
                  value: selectedUnit
                },
                units: v.units,                   //数值类型的所有单位和检查条件
                unitArr: unitArr,                 //可选择的单位数组
                checkArr: checkArr                //可选择的检查条件数组
              }
            } else if (v.type === 'multi-select') {
              obj[v.id] = v.value ? v.value : []
            } else {
              obj[v.id] = v.value
            }
            this.ruleForm = Object.assign({}, this.ruleForm, obj)
          })
          if (this.ruleForm || Object.keys(this.ruleForm).length > 0) {
            this.ruleForm = this.getImagesIDs(this.ruleForm)
          }
          this.clearValidateForm()
        }
      }
    }
}

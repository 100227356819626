


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class ATSComponentsCaseForm extends Vue<globalMixins> {
  @Prop()
    message: string
  @Prop()
    fieldId: number
  @Prop()
    fileListValue: any[]

  api: string = ''
  uploadApi: string = ''
  dialogImageUrl: string = ''
  dialogVisible: boolean = false
  // 组合成el-uoload能是识别出来的数组，key为(name, url)
  imgListArr: any[] = []
  // 把后台上传的dicom文件数据, 放在当前组件内部可以操作
  currentImgLists: any[] = []
  get headers () {
    const Bearer = 'Bearer '
    return {Authorization: (Bearer + localStorage.getItem('token'))}
  }
  created () {
    this.getOperateLists()
    this.imgListArr = this.resetFileKey(this.fileListValue, this.imgListArr)
    if (process.env.NODE_ENV === 'production') {
      let domation = window.location.host
      this.api = `https://${domation}/api`
    } else {
      this.api = 'https://test.myathena.cn/api'
    }
    this.uploadApi = this.api + '/files'
  }
  // 自己内部可以操作的数组
  getOperateLists () {
    this.currentImgLists = this.fileListValue
  }
  // 组合成el-uoload能是识别出来的数组(name, url)
  resetFileKey (list, newList) {
    if (list === null) {
      newList = []
    } else {
      list.slice(0, list.length).map((item: any) => {
        newList.push({
          ...item,
          name: item.filename
        })
      })
    }
    return newList
  }
  handlePictureCardPreview (file) {
    this.dialogImageUrl = file.url
    this.dialogVisible = true
  }
  clearFiles () {
    (this.$refs.uploador as any).clearFiles()
  }
  handleError (err, file, fileList) {
    this.errorMsg(err.error.message)
  }
  handleSuccess(response, file, fileList) {
    this.$emit('change', fileList, this.fieldId)
  }
  handleRemove (file, filelist) {
    this.currentImgLists = filelist
    this.$emit('change', filelist, this.fieldId)
  }
}
